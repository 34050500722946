@use '../../styles/variables' as v;
span.header-progress{
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background: transparent;
    height: 2px;
    > span{
        background: var(--branch-color);
        transition: .2s ease all;
    }
}
.main-header{
    --background-color: 255, 255, 255;
    --text-color: 0, 0, 0;
    --complementary-color: 239, 62, 48;
    --complementary-text: 0, 0, 0;
    position: relative;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    transition: all .5 ease;
    .logo{
        z-index: 9;
        padding: 2px 0;
        position: relative;
        a{
            font-weight: 600;
            font-size: 1.5rem;
            transition: .125s ease all;
            display: block;
            color: v.$text-base;
            font-family: v.$font-rubik;
            &:hover{
                color: v.$text-base;
            }
            &:active{
                transform: scale(.95);
            }
        }
    }
    .header-upper {
        z-index: 5;
        width: 100%;
        position: fixed;
        transition: all .25 ease;
        background: rgba($color: var(--background-color), $alpha: 0);
        transition: .5s ease all;
        border-bottom: 1px solid transparent;
        border-color: v.$decorative-subdued;
        backdrop-filter: blur(10px);
        height: 5rem;
        &.curso,
        &.aprende{
            background: rgba($color: var(--background-color), $alpha: .9);
        }
        &.scrolled{
            background: rgba($color: var(--background-color), $alpha: .9);
        }
        .container{
            --bs-gutter-x: 1.5rem;
            --bg-gutter-y: 0;
            width: 100%;
            padding-right: calc(var(--bs-gutter-x)* .5);
            padding-left: calc(var(--bs-gutter-x)* .5);
            margin-right: auto;
            margin-left: auto;
            height: 100%;
            .header-inner{
                padding: 1rem 0;
                transition: all .5s ease;
                display: flex;
                height: 100%;
                .logo-outer{
                    flex: none;
                    .logo.colored{
                        color: rgba($color: var(--text-color), $alpha: 1);
                        a{
                            color: currentColor;
                            span {
                                color: currentColor;
                            }
                        }
                    }
                }
                .nav-outer{
                    height: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    display: flex;
                    .main-menu{
                        .navbar-{
                            &collapse{
                                padding: 0;
                                flex-basis: 100%;
                                flex-grow: 1;
                                display: flex;
                                align-items: center;
                                height: 100%;
                                > ul {
                                    list-style: none;
                                    display: flex;
                                    height: 100%;
                                    li{
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        a{
                                            --line-width: calc(100% - 1rem);
                                            padding: .75rem .5rem;
                                            font-size: 1rem;
                                            display: flex;
                                            align-items: center;
                                            height: 2.25rem;
                                            font-weight: 500;
                                            position: relative;
                                            text-transform: capitalize;
                                            color: rgba($color: var(--text-color), $alpha: .8);
                                            transition: all .5s ease;
                                            font-family: v.$font-branch;
                                            &::before{
                                                content: "";
                                                left: .5rem;
                                                width: 0%;
                                                transition: .2s ease width, .5s ease background-color;
                                                height: 2px;
                                                background-color: currentColor;
                                                position: absolute;
                                                bottom: 0;
                                                border-radius: 10px;
                                            }
                                            &:hover{
                                                color: rgba($color: var(--text-color), $alpha: 1);
                                                &::before{
                                                    content: "";
                                                    width: 20%;
                                                }
                                            }
                                            &.active{
                                                &::before{
                                                    width: var(--line-width);
                                                }
                                                &.removeLineAnimation {
                                                    &::before{
                                                        bottom: -.5rem;
                                                        opacity: 0;
                                                        visibility: hidden;
                                                    }
                                                }
                                                &.removeLine {
                                                    &::before{
                                                        bottom: 0;
                                                        width: 0%;
                                                        transition: 0s;
                                                        transition-delay: bottom 1s;
                                                    }
                                                }
                                                &.removeLineTransition{
                                                    &::before{
                                                        bottom: 0;
                                                        width: 0%;
                                                        transition: .2s ease all;
                                                    }
                                                    &:hover{
                                                        &::before{
                                                            content: "";
                                                            width: 20%;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .menu-btns{
                    .button{
                        color: rgba($color: var(--text-color), $alpha: 1);
                        border-color: rgba($color: var(--text-color), $alpha: .4);
                        &:hover{
                            border-color: currentColor;
                            color: rgba($color: var(--complementary-text), $alpha: 1);
                            background: rgba($color: var(--complementary-color), $alpha: 1);
                        }
                        &.menu{
                            color: rgba($color: var(--complementary-text), $alpha: 1);
                            background: rgba($color: var(--complementary-color), $alpha: 1);
                            &:hover{
                                border-color: currentColor;
                                color: rgba($color: var(--text-color), $alpha: 1);
                                background: rgba($color: var(--background-color), $alpha: .5);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .main-header{
        .header-upper{
            .container{
                .header-inner{
                    .nav-outer{
                        .main-menu{
                            .navbar-{
                                &collapse{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 510px) {
    .main-header{
        .header-upper{
            .container{
                .header-inner{
                    .menu-btns{
                        .hider{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}