$font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
$font-branch: "Poppins", system-ui;
$font-rubik: "Rubik Mono One", monospace;

$green-color: #0f0;
$main-color: #9f9f9f;
$heading-color: #fff;
$primary-color-laravel: #EF3E30;
$primary-color-asp: #9A89D2;
$lighter-color: #ccc;
$subtitle-color: #ccc;
$black-color: #000;
$border-color: rgba(119, 119, 125, .2);
$button-border: rgba(48, 48, 102, 0.2);
$body-background: #f7f9f9;
$modal-background: #fff;
$grey-bg: #E6EAEE;
$lazy-image-background: #9a89d213;

$decorative-subdued: #bfbfbf;
$decorative-highlight: #9f9f9f;

$input-base: transparent;
$input-highlight: #efefef;

$text-base: #313131;
$text-base-inverted: #fff;
$text-base-subdued: #515151;
$text-highlight: #000;
$text-subdued: #77777d;
$text-link: #a8004f;
$text-link-hover: #e6026c;


/* $green-color: #0f0;
$main-color: #9f9f9f;
$heading-color: #fff;
$primary-color: #EF3E30;
$lighter-color: #ccc;
$subtitle-color: #ccc;
$black-color: #000;
$border-color: rgba(119, 119, 125, .2);
$button-border: rgba(48, 48, 102, 0.2);
$body-background: #000;
$modal-background: #121212;
$grey-bg: #E6EAEE;
$lazy-image-background: #242424;

$decorative-subdued: #292928;
$decorative-highlight: #393939;

$input-base: transparent;
$input-highlight: #1a1a1a;

$text-base: #cecece;
$text-base-inverted: #000;
$text-base-subdued: #9f9f9f;
$text-highlight: #FFF;
$text-subdued: #77777d; */