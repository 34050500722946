@use '../styles/variables' as v;
.pace {
	-webkit-pointer-events: none;
	pointer-events: none;

	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.pace-inactive {
	display: none;
}

.pace .pace-progress {
	background: rgb(3,155,229);
    background: linear-gradient(90deg, rgba(3,155,229,1) 0%, rgba(0,137,123,1) 50%);
	position: fixed;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: .25em;
}
section{
    overflow: hidden;
    margin-bottom: calc(1.5rem * 0.5 * 2);
}
.background{
    position: absolute;
    top: 0;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: auto;
    &-start{
        width: 100%;
        height: 100vh;
        background: 
            linear-gradient(180deg, rgba(255,255,255) 0%, transparent 100%),
            linear-gradient(135deg, rgba(172, 153, 234, 0%) 0%, rgba(172, 153, 234, 10%) 100%), 
            linear-gradient(225deg, rgba(255, 255, 255, 10%) 0%, rgba(249, 50, 44, 10%) 100%);
        &.first{
            background: 
                linear-gradient(135deg, rgba(172, 153, 234, 0%) 0%, rgba(172, 153, 234, 10%) 100%), 
                linear-gradient(225deg, rgba(255, 255, 255, 10%) 0%, rgba(249, 50, 44, 10%) 100%)
            ;
        }    
    }
    &-end{
        width: 100%;
        height: 100vh;
        background: 
            linear-gradient(0deg, rgba(255,255,255) 0%, transparent 100%),
            linear-gradient(45deg, rgba(172, 153, 234, 0) 0%, rgba(172, 153, 234, 0.1) 100%), 
            linear-gradient(315deg, rgba(255, 255, 255, 0.1) 0%, rgba(249, 50, 44, 0.1) 100%);
    }
}
.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 80vh;
    .circular-progress{
        color: v.$primary-color-laravel;
        font-size: 2rem;
    }
}
.home{
    padding-top: 5rem;
    background: #FFF;
}

.section-divider{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    margin-bottom: 1rem;
    &::before,
    &::after{
        content: "";
        background: v.$primary-color-laravel;
    }
    &::before{
        width: 3px;
        height: 6rem;
        border-radius: 10px;
    }
    &::after{
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 100px;
        margin-top: -.5rem;
    }
}
.section{
    padding-inline: 1rem;
    position: relative;
    max-width: 970px;
    width: 100%;
    margin: 0 auto 0;
    padding-bottom: 2rem;
    &.first {
        padding-top: 18vh;
    }
    &.one-page{
        min-height: 50vh;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }
    &.presentation{
        display: flex;
        flex-flow: column;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        h2{
            text-align: center;
            line-height: 1.1;
            font-size: 3rem;
            > span{
                font-weight: 600;
                font-size: 1.25em;
                color: v.$primary-color-laravel;
            }
        }
        .buttons{
            display: flex;
            gap: 1rem;
            flex-flow: row wrap;
            .button{
                min-width: 11.975rem;
                flex: 1;
            }
        }
    }
    .about-section{
        flex: 0 0 auto;
        display: flex;
        gap: .5rem;
        flex-flow: column;
        max-width: 700px;
        h2{
            font-size: 2rem;
            line-height: 1.25;
        }
        .button{
            min-width: 150px;
            width: max-content;
        }
    }
}
.work-area{
    .experience-section{
        flex: 0 0 auto;
        width: 50%;
    }
    .education-section{
        flex: 0 0 auto;
        width: 50%;
    }
    .work-list{
        margin-top: 1.5rem;
        display: flex;
        gap: 1rem;
        flex-flow: column;
    }
}
.services-area{
    .service-grid{
        margin-top: 1.5rem;
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(auto-fill, minmax(18.5rem, 1fr));
    }
}
.testimonial-area{
    margin-top: 2rem;
}
.projects-area{
    padding: 1rem 0;
}
.projects-wrapper{
    display: grid;
    gap: 1rem;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
}
.pricing-area{
    .pricing-grid{
        margin-top: 2rem;
        display: flex;
        flex-flow: row wrap;
        gap: 1.5rem;
        justify-content: center;
        .pricing-card{
            flex: 0 0 auto;
            max-width: 300px;
        }
    }
}
.home-section{
    padding: 1rem;
    padding-bottom: 2rem;
    border-radius: 1rem;
    border: 1px solid transparent;
    border-radius: 16px;
    &.color-{
        &base{
            background: #fff;
        }
    }
    &.border-dash{
        border-width: 0;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23333' stroke-width='3' stroke-dasharray='18%2c 17' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");

    }
    .section-title{
        display: flex;
        flex-flow: column;
        gap: .5rem;
        h2{
            font-size: 1.875rem;
        }
        p{
            max-width: 600px;
            margin: 0 auto;
        }
        .button{
            margin-top: 1rem;
        }
        &.align-center{
            text-align: center;
        }
    }
}
.company-scroll.inner{
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 5rem;
    .wrapper{
        position: absolute;
        display: flex;
        height: 100%;
    }
    section{
        display: flex;
        height: 100%;
        align-items: center;
        animation: swipe var(--speed) linear infinite backwards;
    }

    .image{
        display: flex;
        align-items: center;
        max-height: 100%;
        height: 100%;
        padding: 1.25rem 0;
    }

    img {
        padding: 0 1rem;
        object-fit: cover;
        width: auto;
        height: 100%;
    }

    @keyframes swipe {
        0% {
            transform: translate(0);
        }
        100% {
            transform: translate(-100%);
        }
    }
}
.aprende-area{
    .section-body{
        max-width: 900px;
        margin: 0 auto;
        margin-top: 2rem;
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        gap: 1rem;
    }
}
.contact-area{
    .section-body{
        display: flex;
        gap: 1.5rem;
        .data,
        .form{
            --shadow-color: 120, 90, 221; 
            border-radius: 1rem;
            padding: 2rem !important;
            flex: 1;
            box-shadow: 0 .5rem 45px 0 rgba($color: var(--shadow-color), $alpha: .01), 
                    0 .5rem 41px 0 rgba($color: var(--shadow-color), $alpha: .03), 
                    0 .5rem 41px 0 rgba($color: var(--shadow-color), $alpha: .03), 
                    0 .5rem 26px 0 rgba($color: var(--shadow-color), $alpha: .06),
                    0 .5rem 14px 0 rgba($color: var(--shadow-color), $alpha: .07),
            ;
        }
        .data{
            flex: .4;
            ul{
                list-style: none;
                display: flex;
                flex-flow: column;
                gap: 1rem;
                svg{
                    color: var(--branch-color);
                }
                h3{
                    color: v.$text-base;
                }
                a{
                    color: v.$text-link;
                    &:hover{
                        color: v.$text-link-hover;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media (min-width: 1400px) {
    .container{
        max-width: 1200px;
    }
}

@media only screen and (max-width: 960px) {
    .container{
        max-width: 700px;
    }
    .work-area{
        margin-top: 1rem;
        .experience-section{
            width: 100%;
            margin-bottom: calc(var(--bs-gutter-x) * 0.5 * 3);
            
        }
        .education-section{
            width: 100%;
        }
    }
    .about-area{
        padding-top: 7.5rem;
        padding-bottom: 1rem;
        .about-image{
            flex: 0 0 auto;
            width: 100%;
            text-align: center;
            margin-bottom: calc(var(--bs-gutter-x) * 0.5 * 3);
            img {
                max-width: 100%;
            }
        }
        .about-section{
            flex: 0 0 auto;
            width: 100%;
        }
    }
    .pricing-area{
        .pricing-grid{
            .pricing-card{
                width: 100%;
                min-width: 100%;
                flex: 1;
                max-width: none;
            }
        }
    }
    .contact-area{
        .section-body{
            display: flex;
            flex-flow: column;
            gap: 1.5rem;
            .data,
            .form{
                flex: 1;
            }
        }
    }
}
@media only screen and (max-width: 540px) {
    .home-section{
        padding: 1rem 0;
    }
    .contact-area{
        .form{
            padding: 0 !important;
            border: none !important;
        }
    }
}